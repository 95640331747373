import React, { useState } from 'react';

interface LifeStageQuizProps {
  onComplete: (results: any) => void;
}

const questions = [
  {
    question: "How much are you willing to invest in your business?",
    options: ["$0-$100", "$100-$1000", "$1000-$10,000"]
  },
  {
    question: "How much time can you commit to your business per week?",
    options: ["5-25 hours", "25-50 hours", "50-75 hours"]
  },
  {
    question: "What is your current occupation?",
    options: ["Student", "Unemployed", "9-5 Employee", "Business Owner"]
  }
];

const LifeStageQuiz: React.FC<LifeStageQuizProps> = ({ onComplete }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<string[]>(new Array(questions.length).fill(''));

  const handleAnswer = (answer: string) => {
    const newAnswers = [...answers];
    newAnswers[currentQuestion] = answer;
    setAnswers(newAnswers);
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      onComplete(newAnswers);
    }
  };

  return (
    <div className="text-center">
      <h2 className="text-2xl font-bold mb-6 text-indigo-800">{questions[currentQuestion].question}</h2>
      <div className="w-full bg-gray-200 rounded-full h-2.5 mb-6">
        <div className="bg-indigo-600 h-2.5 rounded-full" style={{ width: `${((currentQuestion + 1) / questions.length) * 100}%` }}></div>
      </div>
      <div className="space-y-4">
        {questions[currentQuestion].options.map((option, index) => (
          <button
            key={index}
            onClick={() => handleAnswer(option)}
            className="w-full py-3 px-6 bg-indigo-100 hover:bg-indigo-200 text-indigo-800 rounded-lg font-semibold transition duration-300"
          >
            {option}
          </button>
        ))}
      </div>
      <p className="mt-6 text-sm text-gray-600">Question {currentQuestion + 1} of {questions.length}</p>
    </div>
  );
};

export default LifeStageQuiz;