import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import QuizIntroduction from './components/QuizIntroduction';
import PersonalityQuiz from './components/PersonalityQuiz';
import LifeStageQuiz from './components/LifeStageQuiz';
import UserInfoForm from './components/UserInfoForm';
import BusinessRecommendations from './components/BusinessRecommendations';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';
import { QuizResults } from './types/quizTypes';

const App: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<string>('landing');
  const [quizResults, setQuizResults] = useState<QuizResults>({ personality: [], lifeStage: [] });
  const [userInfo, setUserInfo] = useState<any>({});

  const handleStartQuiz = () => {
    setCurrentStep('introduction');
  };

  const handleQuizComplete = (results: QuizResults) => {
    setQuizResults(results);
    setCurrentStep('userInfo');
  };

  const handleUserInfoSubmit = (info: any) => {
    setUserInfo(info);
    setCurrentStep('recommendations');
  };

  return (
    <Router>
      <div className="min-h-screen bg-gradient-to-br from-purple-300 to-purple-800 flex items-center justify-center p-4">
        <div className="w-full max-w-3xl p-8 bg-white rounded-lg shadow-[0_20px_60px_-15px_rgba(0,0,0,0.3)] transition-all duration-300 hover:shadow-[0_30px_80px_-20px_rgba(0,0,0,0.4)]">
          <Routes>
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="/" element={
              <>
                {currentStep === 'landing' && <LandingPage onStart={handleStartQuiz} />}
                {currentStep === 'introduction' && <QuizIntroduction onContinue={() => setCurrentStep('personalityQuiz')} />}
                {currentStep === 'personalityQuiz' && <PersonalityQuiz onComplete={(results) => { setQuizResults({ ...quizResults, personality: results }); setCurrentStep('lifeStageQuiz'); }} />}
                {currentStep === 'lifeStageQuiz' && <LifeStageQuiz onComplete={(results) => handleQuizComplete({ ...quizResults, lifeStage: results })} />}
                {currentStep === 'userInfo' && <UserInfoForm onSubmit={handleUserInfoSubmit} investmentAmount={quizResults.lifeStage[0]} />}
                {currentStep === 'recommendations' && <BusinessRecommendations quizResults={quizResults} userInfo={userInfo} />}
              </>
            } />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;