import React, { useState } from 'react';
import { QuizResults } from '../types/quizTypes';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface BusinessRecommendationsProps {
  quizResults: QuizResults;
  userInfo: any;
}

interface BusinessIdea {
  title: string;
  description: string;
  steps: string[];
}

const businessIdeas: BusinessIdea[] = [
  {
    title: "Freelance Digital Marketing Services",
    description: "Offer specialized digital marketing services to businesses looking to improve their online presence.",
    steps: [
      "Choose a niche: Focus on a specific type of marketing (e.g., social media, SEO, content marketing, PPC) or a particular industry.",
      "Learn the basics: Take online courses from platforms like Udemy, Coursera, or HubSpot Academy to develop key digital marketing skills.",
      "Build a portfolio: Start by offering free or low-cost services to friends, family, or small businesses to build a portfolio.",
      "Find clients: Use platforms like Upwork, Fiverr, or LinkedIn to connect with clients looking for digital marketing services."
    ]
  },
  {
    title: "Online Course Creation",
    description: "Create and sell online courses on topics you're knowledgeable about.",
    steps: [
      "Pick a topic: Identify something you're knowledgeable about that others would want to learn (e.g., cooking, coding, photography, or personal development).",
      "Create the course: Plan your lessons and create video tutorials using simple tools like a smartphone and screen-recording software (e.g., Loom, OBS Studio).",
      "Choose a platform: Host your course on platforms like Udemy, Teachable, or Skillshare, or create your own website using tools like WordPress and Thinkific.",
      "Market your course: Use social media, blogging, or email marketing to promote your course and attract students."
    ]
  },
  {
    title: "AI Virtual Assistant Services",
    description: "Provide AI-powered virtual assistant services to businesses and entrepreneurs.",
    steps: [
      "Choose the right AI tools: Start by exploring AI tools like ChatGPT for customer queries, Grammarly for writing assistance, Zapier for workflow automation, or Jasper for content creation.",
      "Define your services: Decide which AI-powered services you want to provide, such as automating customer service, managing emails, generating reports, or creating social media content using AI.",
      "Build your platform: Create a simple website or landing page showcasing your services, emphasizing how you combine AI with human expertise to provide fast, affordable, and efficient solutions.",
      "Find clients: Reach out to startups, small business owners, or solopreneurs who need help with routine tasks but want the efficiency AI offers. Use platforms like LinkedIn, Freelancer, or Upwork.",
      "Scale your offerings: As you grow, you can hire other VAs or integrate more advanced AI tools to expand the services you offer."
    ]
  }
];

const BusinessRecommendations: React.FC<BusinessRecommendationsProps> = ({ quizResults, userInfo }) => {
  const [openIdeas, setOpenIdeas] = useState<number[]>([]);

  const toggleIdea = (index: number) => {
    setOpenIdeas(prev => 
      prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
    );
  };

  return (
    <div className="max-w-3xl mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-indigo-800 text-center">Your Personalized Business Recommendations</h2>
      <p className="text-lg mb-8 text-gray-600 text-center">Based on your profile, here are business ideas tailored just for you, {userInfo.name}!</p>
      <div className="space-y-6">
        {businessIdeas.map((idea, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-md">
            <div 
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleIdea(index)}
            >
              <h3 className="text-xl font-semibold text-indigo-700">{idea.title}</h3>
              {openIdeas.includes(index) ? <ChevronUp /> : <ChevronDown />}
            </div>
            <p className="text-gray-600 mt-2">{idea.description}</p>
            {openIdeas.includes(index) && (
              <div className="mt-4">
                <h4 className="font-semibold mb-2 text-indigo-600">How to start:</h4>
                <ol className="list-decimal list-inside text-gray-700 space-y-2">
                  {idea.steps.map((step, stepIndex) => (
                    <li key={stepIndex}>{step}</li>
                  ))}
                </ol>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BusinessRecommendations;