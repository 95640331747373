import React, { useState } from 'react';

interface PersonalityQuizProps {
  onComplete: (results: any) => void;
}

const questions = [
  "I enjoy coming up with creative ideas rather than focusing on data and planning.",
  "I feel more energized when working with a group of people rather than working alone.",
  "I prefer hands-on tasks over developing long-term strategies.",
  "I am comfortable taking big risks for the possibility of big rewards.",
  "I enjoy working directly with people more than focusing on tasks and details."
];

const PersonalityQuiz: React.FC<PersonalityQuizProps> = ({ onComplete }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<number[]>(new Array(questions.length).fill(0));

  const handleAnswer = (value: number) => {
    const newAnswers = [...answers];
    newAnswers[currentQuestion] = value;
    setAnswers(newAnswers);
  };

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      onComplete(answers);
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const isAnswered = answers[currentQuestion] !== 0;

  return (
    <div className="text-center">
      <h2 className="text-2xl font-bold mb-6 text-indigo-800">{questions[currentQuestion]}</h2>
      <div className="w-full bg-gray-200 rounded-full h-2.5 mb-6">
        <div className="bg-indigo-600 h-2.5 rounded-full" style={{ width: `${((currentQuestion + 1) / questions.length) * 100}%` }}></div>
      </div>
      <div className="flex justify-between items-center mb-4">
        <span className="text-sm text-gray-600">Strongly Disagree</span>
        <span className="text-sm text-gray-600">Neutral</span>
        <span className="text-sm text-gray-600">Strongly Agree</span>
      </div>
      <div className="flex justify-between mb-8">
        {[1, 2, 3, 4, 5, 6, 7].map((value) => (
          <button
            key={value}
            onClick={() => handleAnswer(value)}
            className={`w-10 h-10 rounded-full ${
              answers[currentQuestion] === value ? 'bg-indigo-600 text-white' : 'bg-indigo-100 text-indigo-800'
            } hover:bg-indigo-200 font-semibold transition duration-300`}
          >
            {value}
          </button>
        ))}
      </div>
      <div className="flex justify-between mt-8">
        <button
          onClick={handlePrevious}
          disabled={currentQuestion === 0}
          className={`px-6 py-2 rounded-full ${
            currentQuestion === 0 ? 'bg-gray-300 text-gray-500' : 'bg-indigo-600 text-white hover:bg-indigo-700'
          } transition duration-300`}
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          disabled={!isAnswered}
          className={`px-6 py-2 rounded-full ${
            isAnswered ? 'bg-indigo-600 text-white hover:bg-indigo-700' : 'bg-gray-300 text-gray-500'
          } transition duration-300`}
        >
          {currentQuestion === questions.length - 1 ? 'Finish' : 'Next'}
        </button>
      </div>
      <p className="text-sm text-gray-600 mt-4">Question {currentQuestion + 1} of {questions.length}</p>
    </div>
  );
};

export default PersonalityQuiz;