import React from 'react';
import { Rocket } from 'lucide-react';
import { Link } from 'react-router-dom';

interface LandingPageProps {
  onStart: () => void;
}

const LandingPage: React.FC<LandingPageProps> = ({ onStart }) => {
  return (
    <div className="text-center">
      <h1 className="text-4xl font-bold mb-4 text-indigo-800">Gain the confidence to turn your business idea into reality.</h1>
      <p className="text-xl mb-8 text-gray-600">Join now for step-by-step guidance and a community that will help you build your business with ease.</p>
      <button
        onClick={onStart}
        className="bg-indigo-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-indigo-700 transition duration-300"
      >
        Get Started
      </button>
      <div className="mt-12 flex justify-center">
        <Rocket size={120} className="text-indigo-500" />
      </div>
      
      <div className="mt-8 text-sm text-gray-600">
        <Link to="/terms" className="hover:underline">Terms & Conditions</Link>
        {' | '}
        <Link to="/privacy" className="hover:underline">Privacy Policy</Link>
      </div>
    </div>
  );
};

export default LandingPage;