import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

interface UserInfoFormProps {
  onSubmit: (info: any) => void;
  investmentAmount: string;
}

const UserInfoForm: React.FC<UserInfoFormProps> = ({ onSubmit, investmentAmount }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitting(true);
    setError(null);

    const form = event.target as HTMLFormElement;
    const data = new FormData(form);
    data.append('form-name', 'user-info');
    data.append('investmentAmount', investmentAmount);

    try {
      const response = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(data as any).toString(),
      });

      if (response.ok) {
        console.log('Form submission successful');
        onSubmit(Object.fromEntries(data));
        
        // Option 1: Show alert
        alert('Thank you for your submission!');
        
        // Option 2: Redirect (uncomment to use)
        // navigate('/success');

        // Reset form
        setFormData({ name: '', email: '', phone: '' });
      } else {
        throw new Error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="text-center">
      <h2 className="text-2xl font-bold mb-6 text-indigo-800">Almost there! Let's get to know you better</h2>
      <form onSubmit={handleSubmit} name="user-info" method="POST" data-netlify="true" className="space-y-6">
        <input type="hidden" name="form-name" value="user-info" />
        <p className="hidden">
          <label>
            Don't fill this out if you're human: <input name="bot-field" />
          </label>
        </p>
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">Your Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            placeholder="John Doe"
            className="mt-1 w-full p-2 border border-gray-300 rounded-lg h-10"
          />
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Your Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            placeholder="johndoe@example.com"
            className="mt-1 w-full p-2 border border-gray-300 rounded-lg h-10"
          />
        </div>
        <div>
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone Number (with country code)</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            placeholder="+1234567890"
            className="mt-1 w-full p-2 border border-gray-300 rounded-lg h-10"
          />
        </div>
        <input type="hidden" name="investmentAmount" value={investmentAmount} />
        {error && <p className="text-red-500 text-sm">{error}</p>}
        <button
          type="submit"
          disabled={submitting}
          className={`w-full p-2 h-12 ${
            submitting ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'
          } text-white rounded-lg transition duration-300`}
        >
          {submitting ? 'Submitting...' : 'Claim Your Business Idea Now!'}
        </button>
      </form>
      <p className="mt-4 text-sm text-gray-600">
        By submitting, you agree to our{' '}
        <Link to="/terms" className="text-indigo-600 hover:underline">
          Terms & Conditions
        </Link>{' '}
        and{' '}
        <Link to="/privacy" className="text-indigo-600 hover:underline">
          Privacy Policy
        </Link>
        .
      </p>
    </div>
  );
};

export default UserInfoForm;