import React from 'react';

const TermsConditions: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
      <div className="prose max-w-none overflow-y-auto max-h-[70vh] bg-white rounded-lg shadow-md p-6">
        <p>Welcome to www.Vosco.io!</p>
        <p>These terms and conditions outline the rules and regulations for the use of VosCo's Website, located at www.vosco.io.</p>
        <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use www.Vosco.io if you do not agree to take all of the terms and conditions stated on this page.</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-4">Cookies:</h2>
        <p>The website uses cookies to help personalize your online experience. By accessing www.Vosco.io, you agreed to use the required cookies.</p>
        <p>A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you.</p>
        <p>We may use cookies to collect, store, and track information for statistical or marketing purposes to operate our website. You have the ability to accept or decline optional Cookies. There are some required Cookies that are necessary for the operation of our website. These cookies do not require your consent as they always work. Please keep in mind that by accepting required Cookies, you also accept third-party Cookies, which might be used via third-party provided services if you use such services on our website, for example, a video display window provided by third parties and integrated into our website.</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-4">License:</h2>
        <p>Unless otherwise stated, VosCo and/or its licensors own the intellectual property rights for all material on www.Vosco.io. All intellectual property rights are reserved. You may access this from www.Vosco.io for your own personal use subjected to restrictions set in these terms and conditions.</p>
        <p>You must not:</p>
        <ul className="list-disc pl-6">
          <li>Copy or republish material from www.Vosco.io</li>
          <li>Sell, rent, or sub-license material from www.Vosco.io</li>
          <li>Reproduce, duplicate or copy material from www.Vosco.io</li>
          <li>Redistribute content from www.Vosco.io</li>
        </ul>
        <p>This Agreement shall begin on the date hereof.</p>
        <p>Parts of this website offer users an opportunity to post and exchange opinions and information in certain areas of the website. VosCo does not filter, edit, publish or review Comments before their presence on the website. Comments do not reflect the views and opinions of VosCo, its agents, and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, VosCo shall not be liable for the Comments or any liability, damages, or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-4">Content Liability:</h2>
        <p>We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-4">Reservation of Rights:</h2>
        <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-4">Removal of links from our website:</h2>
        <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links, but we are not obligated to or so or to respond to you directly.</p>
        <p>We do not ensure that the information on this website is correct. We do not warrant its completeness or accuracy, nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>
        
        <h2 className="text-2xl font-semibold mt-6 mb-4">Disclaimer:</h2>
        <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>
        <ul className="list-disc pl-6">
          <li>Limit or exclude our or your liability for death or personal injury;</li>
          <li>Limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
          <li>Limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
          <li>Exclude any of our or your liabilities that may not be excluded under applicable law.</li>
        </ul>
        <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty.</p>
        <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
      </div>
    </div>
  );
};

export default TermsConditions;